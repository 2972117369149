<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <indexHeader :title="$t('accounts.accounts')"
                     :description="$t('accounts.FromHereYouCanControlYouraccounts')"
                     :explanationPath="path + '/explaination'" />
        <indexTable :searchText="$t('accounts.SearchForAnAccount')"
                    :emptyTableText="$t('accounts.ThereAreNoAccounts')"
                    :buttonRole="$user.admin || $user.role.accounts_add"
                    :localData="true"
                    :emptyTableSubText="$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain')"
                    :cloumns="[
                      {column: 'name',    title:$t('accounts.accountName') ,type: 'mainLink' , sort: true },
                      {column: 'mobile',  title:$t('accounts.phoneNumber')  ,type: 'text'     , sort: true, icon: 'phone' },
                      {column: 'balance', title:$t('accounts.balance')      ,type: 'text'     , sort: true, icon: 'usd-square' },
                      {column: 'stat',    title:$t('accounts.active')      ,type: 'stat'     , sort: true, hideMobile: true },
                      {column: 'options', title:$t('accounts.actions'), type: 'options', options: [
                          {name: 'show'},
                          {name: 'edit',   role: $user.admin || $user.role.accounts_edit},
                          {name: 'link',   role: $site.storesDeposits_allow && ($user.admin|| $user.role.stores_deposits_add), title: 'إيداع إلي حساب', icon: 'donate', link:'/accountsDeposits/create?account_id='},
                          {name: 'link',   role: $site.storesWithdrawals_allow && ($user.admin|| $user.role.stores_withdrawals_add), title: 'سحب من حساب', icon: 'money-bill-alt', link:'/accountsWithdrawals/create?account_id='},
                          {name: 'delete', role: $user.admin || $user.role.accounts_delete},
                      ]}
                    ]"
                    :deleteText="{
                     attention: $t('allerts.Attention'),
                     areYouReallySureToDeleteTheItem: $t('allerts.AreYouReallySureTheClientIsDeletedIfYouDeleteTheCustomerAllInvoicesAndProcessesWillBeDeleted'),
                     close: $t('allerts.close'),
                     confirm: $t('allerts.confirm'),
                     loading: $t('allerts.loading'),
                    }" />
      </div>
    </div>
  </div>
</template>
<script>
  import indexHeader from '../elements/index/indexHeader.vue'
  import indexTable from '../elements/index/indexTable.vue'

  export default {
    data() {
      return {
        path: '/accounts'
      }
    },
    components: {
      indexHeader,
      indexTable
    }
  }

</script>
