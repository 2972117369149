var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('accounts.accounts'),"description":_vm.$t('accounts.FromHereYouCanControlYouraccounts'),"explanationPath":_vm.path + '/explaination'}}),_c('indexTable',{attrs:{"searchText":_vm.$t('accounts.SearchForAnAccount'),"emptyTableText":_vm.$t('accounts.ThereAreNoAccounts'),"buttonRole":_vm.$user.admin || _vm.$user.role.accounts_add,"localData":true,"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"cloumns":[
                    {column: 'name',    title:_vm.$t('accounts.accountName') ,type: 'mainLink' , sort: true },
                    {column: 'mobile',  title:_vm.$t('accounts.phoneNumber')  ,type: 'text'     , sort: true, icon: 'phone' },
                    {column: 'balance', title:_vm.$t('accounts.balance')      ,type: 'text'     , sort: true, icon: 'usd-square' },
                    {column: 'stat',    title:_vm.$t('accounts.active')      ,type: 'stat'     , sort: true, hideMobile: true },
                    {column: 'options', title:_vm.$t('accounts.actions'), type: 'options', options: [
                        {name: 'show'},
                        {name: 'edit',   role: _vm.$user.admin || _vm.$user.role.accounts_edit},
                        {name: 'link',   role: _vm.$site.storesDeposits_allow && (_vm.$user.admin|| _vm.$user.role.stores_deposits_add), title: 'إيداع إلي حساب', icon: 'donate', link:'/accountsDeposits/create?account_id='},
                        {name: 'link',   role: _vm.$site.storesWithdrawals_allow && (_vm.$user.admin|| _vm.$user.role.stores_withdrawals_add), title: 'سحب من حساب', icon: 'money-bill-alt', link:'/accountsWithdrawals/create?account_id='},
                        {name: 'delete', role: _vm.$user.admin || _vm.$user.role.accounts_delete} ]}
                  ],"deleteText":{
                   attention: _vm.$t('allerts.Attention'),
                   areYouReallySureToDeleteTheItem: _vm.$t('allerts.AreYouReallySureTheClientIsDeletedIfYouDeleteTheCustomerAllInvoicesAndProcessesWillBeDeleted'),
                   close: _vm.$t('allerts.close'),
                   confirm: _vm.$t('allerts.confirm'),
                   loading: _vm.$t('allerts.loading'),
                  }}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }